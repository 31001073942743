export const PERMISSIONS = {
  DASHBOARD: {
    VIEW: 'vessel_dashboard_view',
    NOON_REPORT_VIEW: 'vessel_dashboard_ last_noon_report_view',
    DEFECT_LIST_VIEW: 'vessel_dashboard_defects_list_view',
    CREW_LIST_VIEW: 'vessel_dashboard_ crew_list_view',
    EXPIRING_CERTIFICATES_VIEW: 'vessel_dashboard_certificates_view',
    ENGINE_PERFORMANCE_VIEW: 'vessel_dashboard_engine_performance_view',
    UPCOMING_MAINTENANCE_VIEW: 'vessel_dashboard_upcoming_maintenance_view',
    DOWNLOAD: 'dashboard_download'
  },
  PARTICULARS: {
    VIEW: 'vessel_particulars_view',
    EXPORT: 'vessel_particulars_export'
  },
  VESSEL_PLAN: {
    VIEW: 'vessel_plan_view',
    UPLOAD: 'vessel_plan_upload',
    DELETE: 'vessel_plan_delete'
  },
  VESSEL_REPORT: {
    VIEW: 'vessel_report_view',
    EXPORT: 'vessel_report_export'
  },
  GEOLOCATOR: {
    VIEW: 'geolocator_view'
  },
  ENGINE_PERFORMANCE: {
    VIEW: 'engine_performance_view'
  },
  VESSEL_INSPECTION: {
    VIEW: 'vessel_inspection_view',
    EXPORT: 'vessel_inspection_export'
  },
  DEFECT_LIST: {
    VIEW: 'defects_list_view'
  },
  CREW_LIST: {
    VIEW: 'crew_list_view',
    EXPORT: 'crew_list_export'
  },
  CERTIFICATES: {
    VIEW: 'certificates_service_view',
    EXPORT: 'certificate_export'
  },
  INTERNAL_AUDIT: {
    VIEW: 'internal_audit_view',
    EXPORT: 'internal_audit_export'
  },
  ACCOUNTING_REPORT: {
    VIEW: 'accounting_reports_view',
    EXPORT: 'accounting_reports_download'
  },
  BUNKERING_REPORT: {
    VIEW: 'bunkering_reports_view'
  },
  POLICIES: {
    VIEW: 'policies_view',
    ADD: 'policies_add',
    EDIT: 'policies_edit',
    DELETE: 'policies_delete'
  },
  ROLES: {
    VIEW: 'roles_view',
    ADD: 'roles_add',
    EDIT: 'roles_edit',
    DELETE: 'roles_delete'
  },
  USERS: {
    VIEW: 'users_view',
    ADD: 'users_add',
    EDIT: 'users_edit',
    DELETE: 'users_disable'
  },
  FLEETS: {
    VIEW: 'fleets_view',
    ADD: 'fleets_add',
    EDIT: 'fleets_edit',
    DELETE: 'fleets_delete'
  },
  ACCOUNTING_ADMIN: {
    VIEW: 'accounting_admin_view',
    ADD: 'accounting_admin_add',
    EDIT: 'accounting_admin_edit',
    DELETE: 'accounting_admin_delete'
  },
  ACCOUNTING_TYPES: {
    VIEW: 'accounting_types_view',
    ADD: 'accounting_types_add',
    EDIT: 'accounting_types_edit',
    DELETE: 'accounting_types_delete'
  },
  PURCHASE: {
    VIEW: 'purchase_view',
    EXPORT: 'purchase_export'
  },
  PMS: {
    VIEW: 'pms_view',
    EXPORT: 'pms_export',
    SPARES: {
      EXPORT: 'pms_spare_export'
    }
  },
  VESSEL_LIST: {
    MANAGEMENT: {
      VIEW: 'management_view'
    },
    EXPORT: 'vessel_export'
  },
  SP_V3_MIGRATIONS: {
    VIEW: 'vessel_v3_status_view',
    UPDATE: 'vessel_v3_status_update'
  },
  VESSEL_EXPENSE: {
    VIEW: 'vessel_expense_report_view',
    EXPORT: 'operative_statement_export'
  },
  USER_MANUAL: {
    UPLOAD: 'user_manual_upload'
  },
  LUBE_OIL_ANALYSIS_REPORT: {
    VIEW: 'lubeoil_analysis_view'
  },
  REPORTS: {
    VIEW: 'hseq_findings_report_view',
    EXPORT: 'hseq_findings_report_export'
  },
  LO_REPORTS: {
    VIEW: 'lo_report_view',
    EXPORT: 'lo_report_export'
  }
};
