import React, { useContext, useMemo } from 'react';
// import { Nav } from '@synergy-marine-group/synergy-ui';
import { AuthContext } from '../../providers/AuthProvider';
import { PERMISSIONS } from '../../../config/permissions';
import Nav from './nav';

const Navigation = (props) => {
  const { getUserDetails } = useContext(AuthContext);

  const menuItems = useMemo(() => {
    const user = getUserDetails();
    const userPermissions = user?.Permissions || [];
    const items = [
      {
        title: 'Vessels Locator',
        url: '/vessel-locator',
        iconName: 'location_on',
        pinned: true
      },
      {
        title: 'Vessels',
        url: '/vessel-list',
        iconName: 'directions_boat',
        pinned: true
      }
    ];
    if (userPermissions.includes(PERMISSIONS.POLICIES.VIEW)) {
      items.push({
        title: 'Policies',
        url: '/user-management/policies',
        iconName: 'policy',
        pinned: true
      });
    }
    if (userPermissions.includes(PERMISSIONS.ROLES.VIEW)) {
      items.push({
        title: 'Roles',
        url: '/user-management/roles',
        iconName: 'manage_accounts',
        pinned: true
      });
    }
    if (userPermissions.includes(PERMISSIONS.USERS.VIEW)) {
      items.push({
        title: 'Users',
        url: '/user-management/users',
        iconName: 'groups',
        pinned: true
      });
    }
    if (userPermissions.includes(PERMISSIONS.FLEETS.VIEW)) {
      items.push({
        title: 'Fleets',
        url: '/user-management/fleets',
        iconName: 'flag',
        pinned: true
      });
    }
    if (userPermissions.includes(PERMISSIONS.ACCOUNTING_ADMIN.VIEW)) {
      items.push({
        title: 'Accounting',
        url: '/accounting',
        iconName: 'assignment',
        pinned: true
      });
    }
    if (userPermissions.includes(PERMISSIONS.ACCOUNTING_TYPES.VIEW)) {
      items.push({
        title: 'Accounting Report Types',
        url: '/accounting-types',
        iconName: 'assignment',
        pinned: true
      });
    }
    if (userPermissions.includes(PERMISSIONS.SP_V3_MIGRATIONS.VIEW)) {
      items.push({
        title: 'SP V3 Migrations',
        url: '/v3-migrations',
        iconName: 'filter_3',
        pinned: true
      });
    }
    if ((userPermissions.includes(PERMISSIONS.REPORTS.VIEW))
      || (userPermissions.includes(PERMISSIONS.LO_REPORTS.VIEW))) {
      items.push({
        title: 'Reports',
        url: '/reports?tab=1',
        iconName: 'summarize',
        pinned: true
      });
    }
    items.push({
      title: 'Help',
      url: '/help',
      iconName: 'help',
      pinned: true
    });
    return items;
  }, []);

  return (
    <Nav
      menuitems={menuItems}
      bottommenu={[]}
      {...props}
    />
  );
};

export default Navigation;
